<template>
  <div>
    <orders v-if="types=='orders'" :filters="filters" />
  </div>
</template>
<script>    
  import Orders from './types/orders'  

  export default {
    props:{
      filters: {
        type: Object,
        default: null,
      },
      types: {
        type: String,
        default: null,
      },      
    },    
    components: {
      Orders,      
    },     
  }
</script>