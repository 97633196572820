import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import ConfigAPI from '@/config/api'
import Session from '@/handler/session'

Vue.use(VueAxios, axios)

const instance = axios.create();

const servicesAPI = {
  obtenerConfiguracion() {
    var url = ConfigAPI.baseURL + "orders-configuration" + Session.getToken();
    return instance.get(url);
  },  
  getResumen(data) {
    var params = data    
    var url = ConfigAPI.baseURL + "orders/filter/resumen" + Session.getToken();
    return instance.post(url,params);
  },   
}

export default servicesAPI;